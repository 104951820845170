import React from "react";

interface Props {
  children: React.ReactNode;
  display?: boolean;
  as?: string;
  className?: string;
}

const H2: React.FC<Props> = ({ children, display = false, as = "h2", className }) => {
  const classes = `${display ? "text-2xl md:text-3xl" : "text-xl md:text-2xl"} ${className ? className : ""}`
  return React.createElement(as,  { className: classes }, children)
}

export default H2
