import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

const SchemaBlogpost = ({
	headline,
	description,
	imageQuadrat,
	imageRechteck,
	imageQuer,
	datePublished,
	dateModified,
}) => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					siteUrl
				}
			}
			logo: file(relativePath: { eq: "logo_icon_blocked.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	const ldJson = {
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": data.site.siteMetadata.siteUrl,
		},
		headline,
		description,
		image: [imageQuadrat, imageRechteck, imageQuer],
		author: {
			"@type": "Person",
			name: "Andreas Lautenschlager",
			url: "https://www.lautenschlager.de",
		},
		publisher: {
			"@type": "Organization",
			name: "Lautenschlager Marketing & Entwicklung",
			logo: {
				"@type": "ImageObject",
				url: `${data.site.siteMetadata.siteUrl}${data.logo.childImageSharp.gatsbyImageData.images.fallback.src}`,
			},
		},
		datePublished,
		dateModified,
	};

	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
		</Helmet>
	);
};

export default SchemaBlogpost;

SchemaBlogpost.propTypes = {
	headline: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	imageQuadrat: PropTypes.string.isRequired,
	imageRechteck: PropTypes.string.isRequired,
	imageQuer: PropTypes.string.isRequired,
	datePublished: PropTypes.string.isRequired,
	dateModified: PropTypes.string.isRequired,
};
