/* eslint-disable react/no-unstable-nested-components */
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import slugify from "slugify";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import EstimatedReadingTime from "~components/blog/EstimatedReadingTime";
import Grid from "~components/common/Grid";
import Layout from "~components/layouts/Layout";
import SchemaBlogpost from "~components/layouts/schemas/SchemaBlogpost";
import PageHeader from "~components/sections/PageHeader";
import H2 from "~components/typography/H2";

export default function Page({ data, location }) {
	const articleDiv = useRef(null);
	const [scrollTop, setScrollTop] = useState(0);
	const [readingIndicator, setReadingIndicator] = useState(0);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const onScroll = (e) => {
			setScrollTop(e.target.documentElement.scrollTop);
		};
		window.addEventListener("scroll", onScroll);

		if (articleDiv && articleDiv.current) {
			const acrticleScrollHeight = scrollTop - articleDiv.current.offsetTop;
			const acrticleScrollHeightMax = articleDiv.current.scrollHeight;
			const currentPercantage = (acrticleScrollHeight / acrticleScrollHeightMax) * 100;
			if (acrticleScrollHeight > 0 && currentPercantage < 100) {
				setReadingIndicator(Math.ceil(currentPercantage));
			} else if (acrticleScrollHeight < 0) {
				setReadingIndicator(0);
			} else {
				setReadingIndicator(100);
			}
		}

		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	const { blog } = data;
	const naviHeadlines = [];

	const text = parse(blog.text, {
		replace: (domNode) => {
			if (domNode.name === "img") {
				// eslint-disable-next-line no-param-reassign
				domNode.attribs.src = `https://cms.lautenschlager.de${domNode.attribs.src}`;
				const props = attributesToProps(domNode.attribs);
				return (
					<img
						height={domNode.attribs.height}
						src={domNode.attribs.src}
						width={domNode.attribs.width}
						title={domNode.attribs.alt}
						alt={domNode.attribs.alt}
						loading="lazy"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...props}
					/>
				);
			}
			if (domNode.name === "h2") {
				const props = attributesToProps(domNode.attribs);
				const title = domNode.children[0].data;
				const id = slugify(domNode.children[0].data, {
					replacement: "-",
					lower: true,
					strict: true,
					trim: true,
				});
				naviHeadlines.push({
					title,
					id,
					size: 1,
				});
				return (
					<h2
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...props}
						id={id}
					>
						{domToReact(domNode.children)}
					</h2>
				);
			}
			if (domNode.name === "h3") {
				const props = attributesToProps(domNode.attribs);
				const title = domNode.children[0].data;
				const id = slugify(domNode.children[0].data, {
					replacement: "-",
					lower: true,
					strict: true,
					trim: true,
				});
				naviHeadlines.push({
					title,
					id,
					size: 2,
				});
				return (
					<h3
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...props}
						id={id}
					>
						{domToReact(domNode.children)}
					</h3>
				);
			}

			if (
				domNode.name === "a" &&
				typeof domNode.attribs?.href !== "undefined" &&
				domNode.attribs.href.startsWith("/storage/uploads/")
			) {
				const props = attributesToProps(domNode.attribs);
				props.href = props.href.replace("/storage/uploads/", `https://cms.lautenschlager.de/storage/uploads/`);
				// eslint-disable-next-line react/jsx-props-no-spreading
				return <a {...props}>{domToReact(domNode.children)}</a>;
			}
			return domNode;
		},
	});

	const date = new Date(blog.datum);

	return (
		<Layout
			location={location}
			title={blog.titel_seo}
			desc={blog.einleitung_seo}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${blog.facebook.childImageSharp.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={blog.facebook.childImageSharp.gatsbyImageData.width}
			imageFacebookHeight={blog.facebook.childImageSharp.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${blog.twitter.childImageSharp.gatsbyImageData.images.fallback.src}`}
			ogType="article"
		>
			<div
				className={`sticky top-0 z-10 bg-brand ${readingIndicator > 0 ? "block" : "hidden"}`}
				style={{ width: `${readingIndicator}%`, height: 2, transition: "width 1s" }}
			/>
			<SchemaBlogpost
				headline={blog.titel_seo}
				description={blog.einleitung_seo}
				imageQuadrat={`${data.site.siteMetadata.siteUrl}${blog.schema.quadrat.gatsbyImageData.images.fallback.src}`}
				imageRechteck={`${data.site.siteMetadata.siteUrl}${blog.schema.rechteck.gatsbyImageData.images.fallback.src}`}
				imageQuer={`${data.site.siteMetadata.siteUrl}${blog.schema.quer.gatsbyImageData.images.fallback.src}`}
				datePublished={blog.datum}
				dateModified={`${new Date(
					// eslint-disable-next-line no-underscore-dangle
					blog._modified * 1000
				).getFullYear()}-${new Date(
					// eslint-disable-next-line no-underscore-dangle
					blog._modified * 1000
					// eslint-disable-next-line no-underscore-dangle
				).getMonth()}-${new Date(blog._modified * 1000).getDate()}`}
			/>
			<Breadcrumb
				pages={[
					{ name: "Blog", to: "/blog/" },
					{
						name: blog.titel.split("").length > 30 ? `${blog.titel.slice(0, 30)}...` : blog.titel,
						to: `/blog/${blog.titel_slug}/`,
					},
				]}
			/>
			<PageHeader title={blog.titel}>
				<div className="my-4 max-w-xl md:mx-auto">
					<div className="mb-3 space-y-5 text-xs md:text-center">
						<dl className="space-y-2">
							<div>
								<dt className="sr-only">Veröffentlicht am</dt>
								<dd className="font-medium leading-none">
									<time dateTime={blog.datum.toString()}>
										{date.toLocaleDateString("de-DE", {
											weekday: "long",
											year: "numeric",
											month: "long",
											day: "numeric",
										})}
									</time>
								</dd>
							</div>
							<div>
								<span className="font-medium leading-none">
									<EstimatedReadingTime text={blog.text} />
								</span>
							</div>
						</dl>
					</div>
					<p className="text-xl">{blog.einleitung}</p>
				</div>
			</PageHeader>
			<Container noPadding className="my-8">
				<div className="mb-8 text-center">
					<GatsbyImage
						image={getImage(blog.remoteImage)}
						alt={`${blog.titel} | Titelbild`}
						title={`${blog.titel} | Titelbild`}
					/>
				</div>
				<Grid cols={3} className="mx-auto max-w-5xl">
					<div className="md:col-span-2">
						<div className="bg-pattern mx-auto my-8 p-2">
							<H2 display className="mb-5 p-4 font-display" as="span">
								Inhalt
							</H2>
							<nav className="space-y-3 bg-white p-4 text-xl">
								{naviHeadlines.map((entry) => (
									<AnchorLink
										to={`/blog/${blog.titel_slug}/#${entry.id}`}
										className={`block hover:underline ${entry.size === 2 && "pl-3 text-sm"}`}
										id={entry.id}
										key={entry.id}
									>
										{entry.title}
									</AnchorLink>
								))}
							</nav>
						</div>
						<article className="prose prose-xl mx-auto my-8 max-w-none" ref={articleDiv}>
							{text}
						</article>
						<div className="my-5">
							<Button to="/blog/" text="Zu weiteren Artikeln" />
						</div>
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	query ($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		blog(id: { eq: $id }) {
			einleitung
			datum
			_modified
			titel
			text
			titel_slug
			titel_seo
			einleitung_seo
			remoteImage {
				childImageSharp {
					gatsbyImageData
				}
			}
			facebook: remoteImage {
				childImageSharp {
					gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
				}
			}
			twitter: remoteImage {
				childImageSharp {
					gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
				}
			}
			schema: remoteImage {
				quadrat: childImageSharp {
					gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
				}
				rechteck: childImageSharp {
					gatsbyImageData(aspectRatio: 1.33333333, formats: JPG, quality: 100)
				}
				quer: childImageSharp {
					gatsbyImageData(aspectRatio: 1.77777778, formats: JPG, quality: 100)
				}
			}
			thema {
				display
			}
		}
	}
`;
